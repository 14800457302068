:root{
    --clipy: circle(0.0% at 50% 50%);
}


.name_logo_style{
    display: flex;
    justify-content: center;
}

.logo_img{
    width: 50%;
}

.item{
    display: flex;
    justify-content: center;
    text-align: center;
    margin: auto;
    position: relative;
    padding-left: 1rem;
    text-align: center;
}

.item::before{
    content: '';
    display: block;
    background: slateblue;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    clip-path: var(--clipy);
    transition: clip-path 2s;
}

.item:hover::before{
    clip-path: circle(69.6% at 50% 50%);


}

.item:hover{
    color: white;
}

@media (max-width: 415px) {
    
    }