.parallax {
  /* The image used */
  background-image: url("../../Assets/Img/Banner/banner3.webp");

  /* Set a specific height */
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 575px) {
  .parallax {
    background-image: url('../../Assets/Img/Banner/manejo.jpg');

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}