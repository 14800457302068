.box_style{
        overflow: hidden;
        border-radius: 10px;
}

.car_style {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



