.card_style_medium {
    margin: auto;
    padding: 1rem;
    width: 20rem;
    height: auto;
    border-radius: 5px;
    margin-bottom: 1rem;

    box-shadow: 7px 11px 12px -2px rgba(0, 0, 0, 0.68);
    -webkit-box-shadow: 7px 11px 12px -2px rgba(0, 0, 0, 0.68);
    -moz-box-shadow: 7px 11px 12px -2px rgba(0, 0, 0, 0.68);

    background-color: #06283D;

    display: flex;
    flex-direction: column;
}

.card_img_medium {
    width: 100%;
    height: auto;
    border-radius: 5px;
    padding-bottom: 1rem;
}


.card_title_medium{
    font-family: 'Times New Roman', Times, serif;
    font-size: 100%;
    text-align: start;
    margin: 0 10px 10px 10px;
    padding: 5px;
   

    border-radius: 5px;
    background-color: #1363DF;
    color: white;
    text-align: center  ;
    align-items: center;
}

/* .text_redux{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    line-clamp: 4;
    overflow: hidden;
} */
  
.card_texto_medium {
    font-size: 1rem;
    text-align: left;
    height: auto;
    color: white;
}

/* .btn_style{
    text-align: end;
    display: flex;
    flex-direction: column;
    
} */