.whatsapp {
    position:fixed;
    width:60px;
    height:60px;
    bottom:40px;
    right:40px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:35px;
    z-index:1;
  }
  
  .whatsapp-icon {
    margin-top:13px;
  }
